import {Component} from "react";

import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Pagination, Navigation } from 'swiper'

import './styles/featuredCultivators.css';
import {Button} from "react-bootstrap";

import {GetFeaturedCultivators} from "../apiaccess/cmsAccess";


export default class FeaturedCultivators extends Component {

    constructor() {
        super();
        this.state = {
            cultivators: []
        };
    }

    async componentDidMount() {
        try {
            const cultivators = await GetFeaturedCultivators();
            this.setState({cultivators: [...cultivators] })
        }
        catch(error) {
            console.log(error);
        }
    }

    render() {
        return (
            <div className="featured-cultivators">
                <div className="cult-header">
                    <strong> FEATURED CULTIVATORS </strong>
                </div>

                <Swiper

                    breakpoints={
                        {
                            350: {
                                width:350,
                                slidesPerView: 1
                            },
                            641: {
                                width: 640,
                                slidesPerView: 1,
                            },
                            1000: {
                                width:1000,
                                slidesPerView:2,
                                spaceBetween: 0,
                            }
                        }
                    }
                    pagination={{
                        clickable: true,
                    }}
                    modules={[Pagination, Navigation]}
                    navigation
                    className="cultivators-container"
                >
                    {

                        this.state.cultivators.map(cult =>
                            <SwiperSlide key={cult.title}>
                                <div className="swiper-item">
                                    <div className='item-banner'>
                                        <img src={cult.featured_image_url}
                                             alt={cult.title + ' Logo'}
                                             title={cult.title + ' Logo'}
                                        />
                                    </div>
                                    <div className='item-content'>
                                        <div className='item-title'>
                                            {cult.title}
                                        </div>
                                        <div className='item-description'>
                                            {cult.description}
                                        </div>
                                        <Button className='cult-button'>
                                            <a href={cult.learn_more_url} target="_blank" rel="noreferrer">
                                                Learn More
                                            </a>
                                        </Button>
                                    </div>
                                </div>
                            </SwiperSlide>
                        )
                    }
                </Swiper>
            </div>
        );
    }
}
