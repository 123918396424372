import React, {Component} from "react";
import { Carousel } from 'react-responsive-carousel';

import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./styles/homepagebannercarousel.css";

import FiacreSlide1 from '../assets/Slider1.jpg';
import GlacierSlide1 from '../assets/GlacierCannabisBanner.jpg';
import WildFireSlide3webp from '../assets/wildfire_homepage_slider.webp';
import WildFireSlide3png from '../assets/wildfire_homepage_slider.png';

import {ComputeVideoURL} from "../helpers/GenericHelpers";

class HomepageBannerCarousel extends Component {
    render() {
        return (
          <Carousel className="banner" showThumbs={false}
                    swipeable={true}
                    infiniteLoop={true}
                    autoPlay={true}
                    interval={6000}
                    transitionTime={1000}
                    stopOnHover={true}
                    showStatus={false}
                    animationHandler={"fade"}>
              <div>
                  <picture >
                      <source srcSet={WildFireSlide3webp} />
                      <img src={WildFireSlide3png}  alt="Sessions with Anden Chapter 3, WildFire"/>
                  </picture>
                  <div className="banner-text-container">
                      <div className="banner-text fw-light"> SESSIONS WITH ANDEN </div>
                      <div className="banner-text title"> WILDFIRE </div>
                      <div className="banner-text episode-number"> Chapter 3</div>
                      <a href={ComputeVideoURL(3,1)} className="banner-text button"> WATCH NOW </a>
                  </div>
              </div>
              <div>
                  <img alt="Sessions with Anden Chapter 2, Glacier Cannabis" src={GlacierSlide1}/>
                  <div className="banner-text-container">
                      <div className="banner-text fw-light"> SESSIONS WITH ANDEN </div>
                      <div className="banner-text title"> GLACIER CANNABIS </div>
                      <div className="banner-text episode-number"> Chapter 2</div>
                      <a href={ComputeVideoURL(2,1)} className="banner-text button"> WATCH NOW </a>
                  </div>
              </div>
              <div>
                  <img alt="Sessions with Anden Chapter 1, Fiacre, Inc. " src={FiacreSlide1}/>
                  <div className="banner-text-container">
                      <div className="banner-text fw-light"> SESSIONS WITH ANDEN </div>
                      <div className="banner-text title"> FIACRE, INC. </div>
                      <div className="banner-text episode-number"> Chapter 1</div>
                      <a href={ComputeVideoURL(1,1)} className="banner-text button"> WATCH NOW </a>
                  </div>
              </div>
          </Carousel>
        );
    }
}

export default  HomepageBannerCarousel;
